nav {
  position: absolute;
  z-index: 99;
  width: 100%;
  max-width: 1200px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 768px) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  top: 0;
  background: #342847;
  color: #ccc;
}

ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

li {
  height: 100%;
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

li:hover {
  background: #534a6e;
  /* color: #212121; */
}

.fixed-nav {
  position: fixed;
}

.content {
  width: 80%;
  margin: 0 auto;
  text-align: justify;
  padding-top: 20px;
}

.content > div {
  margin: 10px 0;
}
