body {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}

.header {
  padding: 20px;
}

.App {
  text-align: center;
}

.btns {
  text-align: center;
  color: #fff;
}

.webmadewell {
  background-color: white;
}
body {
  margin: 0;
}
.sample-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-image: url("./assets/img-bg-sample-parallax-header.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.sample-header::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: MidnightBlue;
  opacity: 0.3;
}
.sample-header-section {
  position: relative;
  padding: 15% 0 10%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  font-family: "Montserrat", sans-serif;
}
h1 {
  font-weight: 500;
}
h2 {
  font-weight: 400;
}
.sample-section-wrap {
  position: relative;
  background-color: white;
}
.sample-section {
  position: relative;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  @media screen and(max-width: 768px) {
    padding: 20px;
  }
}

.careers-box {
  margin-top: 15px;
  h5 {
    margin-top: 10px !important;
    margin-bottom: 3px !important;
  }
}

.pageup-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

footer {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 272px;
  width: 100%;
  background-color: #181433;

  .footer-box {
    width: 50%;
    text-align: center;
    div {
      margin: 5px;
      p:nth-child(odd) {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  a {
    color: #fff;
  }
  .copyright {
    margin-top: 10px;
    padding: 10px;
    border-top: 1px solid #fff;
  }
}
